.float-container {
  position: absolute;
  top: 7.75rem;
  height: calc(100vh - 11.75rem);
  width: calc(100% - 4rem);
  display: block;
  z-index: 2;
  background-color: $body-bg;
  transform: translateX(150%);
  transition: all 0.25s ease, color 0s;
  overflow: hidden;
  &.show {
    transition: all 0.25s ease, color 0s;
    transform: translateX(0%);
  }
  .float-container-header {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 4px 6px rgba($black, 0.04);
    border-bottom: 1px solid $kbd-bg;
    position: relative;
    padding: 0.5rem 2rem;
    background-color: $white;
    height: 5rem;
  }
  .float-container-header-left {
    .float-container-title {
      display: -webkit-box;
      -webkit-line-clamp: 1;
      -webkit-box-orient: vertical;
      overflow: hidden;
    }
    .go-back {
      cursor: pointer;
    }
  }
  .float-container-header-right {
    .dropdown-toggle,
    .action-icon {
      color: $body-color;
    }
  }

  .float-container-scroll-area {
    padding: 0 2rem;
    position: relative;
    height: calc(100% - #{9.5rem});
  }

  .float-container-scroll-area.full-height {
    height: calc(100% - #{6.5rem}) !important;
  }

  .float-container-footer {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
    align-items: center;
    box-shadow: 0 -4px 6px rgba($black, 0.04);
    border-bottom: 1px solid $kbd-bg;
    bottom: 0;
    left: 0;
    padding: 0 1em;
    background-color: $white;
    height: 4.5rem;
    position: fixed;
    width: 100%;
  }
}

@media (max-width: 575.98px) {
  .float-container{
    top: 6.95rem;
    height: calc(100vh - 11.75rem);
    width: calc(100% - 2.4rem);
  }
}