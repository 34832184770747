//  ================================================================================================
//  File Name: variables.scss
//  Description: Custom component variable
//  ----------------------------------------------------------------------------------------------
//  Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//  Author: PIXINVENT
//  Author URL: http://www.themeforest.net/user/pixinvent
//  ================================================================================================

//  ================================================================================================
//  WARNING: PLEASE DO NOT CHANGE THIS VARIABLE FILE.
//  THIS FILE WILL GET OVERWRITTEN WITH EACH VUEXY HTML TEMPLATE RELEASE.
//  TIP:
//  We suggest you to use this (assets/scss/variables/_variables-components.scss) file for overriding components variables.
//  ================================================================================================

$base-font-size: 14px !default;
$body-direction: ltr !default; // Default ltr, change it to rtl for Right To Left support.
$content-padding: 2rem !default;

//  ------------------------------
//    Colors
//  ------------------------------
$nav-component-border-color: #ddd !default;

$swiper-bg: #f2f4f4 !default;

//  ------------------------------
//    Navbar
//  ------------------------------
$navbar-height: 4.45rem !default;
$horizontal-menu-height: 4.45rem !default;
$footer-height: 3.35rem !default;

//  ------------------------------
//    Main Menu
//  ------------------------------

//main menu dark
$menu-dark-color: #dcdcdc !default;
$menu-dark-bg-color: #10163a !default;

$menu-padding: 10px 15px 10px 15px !default;
$menu-second-level-padding: 10px 15px 10px 20px !default;
$menu-third-level-padding: 10px 15px 10px 53px !default;
$menu-forth-level-padding: 10px 15px 10px 53px !default;

// vertical menu
$menu-expanded-width: 260px !default;
$menu-collapsed-width: 80px !default;

//  ------------------------------
//    Sidebar
//  -------------------------------
$sidebar-width: 260px !default;
$chat-sidebar-width: 360px !default;

//  -------------------------------
//    Avatar
//  -------------------------------

$avatar-size: 32px !default;
$avatar-status-size: 11px !default;
$avatar-status-size-lg: 17px !default;

$avatar-size-xl: 70px !default;
$avatar-size-lg: 50px !default;
$avatar-size-sm: 24px !default;

$avatar-bg: #c3c3c3;
$avatar-group-border: $white;

//  -------------------------------
//  Progress
//  -------------------------------
$progress-size-xl: 1.14rem !default;
$progress-size-lg: 0.857rem !default;
$progress-size-md: 0.57rem !default;
$progress-size-sm: 0.143rem !default;

//  -------------------------------
//  Form
//  -------------------------------

// $input-height, $input-height-lg, $input-height-sm are in variables
$font-size-xs: 0.75rem !default;
$font-size-xl: ($font-size-base + 0.5) !default;
$line-height-xl: 1.7 !default;
$line-height-xs: 1.5 !default;
$input-padding-y-xl: 0.5rem !default;
$input-padding-x-xl: 0.5rem !default;

$input-padding-y-xs: 0.2rem !default;
$input-padding-x-xs: 0.275rem !default;

$border-radius-xl: 0.35rem !default;
$border-radius-xs: 0.12rem !default;
$input-border-radius-xl: $border-radius-xl !default;
$input-border-radius-xs: $border-radius-xs !default;

$input-height-xl: (($font-size-xl * $line-height-xl) + ($input-padding-y-xl * 2)) !default;
$input-height-xs: (($font-size-xs * $line-height-xs) + ($input-padding-y-xs * 2)) !default;
$input-height-sm: (($font-size-sm * $line-height-sm) + ($input-padding-y-sm * 2)) !default;

//  -------------------------------
//  Blank Page Bg Color
//  -------------------------------

$blank-bg-color: #eff2f7 !default;

//  -------------------------------
//  Data Tables Bg Color
//  -------------------------------

$datatable-bg-color: #f8f8f8 !default;

//  -------------------------------
//  Switch
//  -------------------------------
$switch-bg-color: #e2e2e2 !default;
$switch-indicator-color: $white !default;

//  -------------------------------
//  Timeline
//  -------------------------------
$timeline-border-color: $border-color !default;

$timeline-point-size: 1.75rem !default;
$timeline-point-indicator-size: 12px !default;
$timeline-point-indicator-color: $primary !default;
$timeline-point-indicator-wrapper-size: 20px !default;

$timeline-item-min-height: 4rem !default;
$timeline-item-icon-font-size: 0.85rem !default;

$timeline-event-time-size: 0.85rem !default;
$timeline-event-time-color: $text-muted !default;

$form-check-input-width: 1.285rem !default;
$form-check-padding-start: $form-check-input-width + 0.5rem !default;
$form-check-margin-bottom: 0 !default;
$form-check-label-cursor: pointer !default;

$form-check-input-border: 1px solid #d8d6de !default;
$form-check-input-border-radius: 3px !default;
$form-check-input-focus-border: $component-active-bg !default;
$form-check-input-focus-box-shadow: 0 2px 4px 0 rgba($component-active-bg, 0.4) !default;

$form-check-input-checked-bg-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' viewBox='0 0 9.5 7.5'><polyline points='0.75 4.35 4.18 6.75 8.75 0.75' style='fill:none;stroke:%23fff;stroke-linecap:round;stroke-linejoin:round;stroke-width:1.5px'/></svg>") !default;
$form-check-radio-checked-bg-image: none !default;

$form-check-input-indeterminate-color: $component-active-color !default;
$form-check-input-indeterminate-bg-image: url("data:image/svg+xml;charset=utf8,<svg xmlns='http://www.w3.org/2000/svg' width='24' height='24' viewBox='0 0 24 24' fill='none' stroke='#{$form-check-input-indeterminate-color}' stroke-width='2' stroke-linecap='round' stroke-linejoin='round' class='feather feather-minus'><line x1='5' y1='12' x2='19' y2='12'></line></svg>") !default;

$form-check-input-disabled-opacity: 0.65 !default;

$form-switch-color: $component-active-color !default;
$form-switch-width: 3rem !default;
$form-switch-height: 1.7rem !default; // (c)
$form-switch-padding-start: $form-switch-width + 0.5rem !default;
$form-switch-bg-image: url("data:image/svg+xml,<svg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><circle id='path-1' cx='8' cy='8' r='8'></circle><filter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'><feOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset><feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur><feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'></feColorMatrix></filter></defs><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='switches-dot' transform='translate(5.000000, 5.000000)' fill-rule='nonzero'><g id='Oval'><use fill='black' fill-opacity='1' filter='url(#filter-2)' xlink:href='#path-1'></use><use fill='#{$form-switch-color}' xlink:href='#path-1'></use></g></g></g></svg>") !default;
$form-switch-transition: background-position 0.15s ease-in-out, background-color 0.1s ease !default;
$form-switch-focus-color: $form-switch-color !default;
$form-switch-focus-bg-image: url("data:image/svg+xml,<svg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><circle id='path-1' cx='8' cy='8' r='8'></circle><filter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'><feOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset><feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur><feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'></feColorMatrix></filter></defs><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='switches-dot' transform='translate(5.000000, 5.000000)' fill-rule='nonzero'><g id='Oval'><use fill='black' fill-opacity='1' filter='url(#filter-2)' xlink:href='#path-1'></use><use fill='#{$form-switch-focus-color}' xlink:href='#path-1'></use></g></g></g></svg>") !default;

$form-switch-checked-color: $form-switch-color !default;
$form-switch-checked-bg-image: url("data:image/svg+xml,<svg width='26px' height='26px' viewBox='0 0 26 27' version='1.1' xmlns='http://www.w3.org/2000/svg' xmlns:xlink='http://www.w3.org/1999/xlink'><defs><circle id='path-1' cx='8' cy='8' r='8'></circle><filter x='-40.6%' y='-21.9%' width='168.8%' height='168.8%' filterUnits='objectBoundingBox' id='filter-2'><feOffset dx='-1' dy='2' in='SourceAlpha' result='shadowOffsetOuter1'></feOffset><feGaussianBlur stdDeviation='1.5' in='shadowOffsetOuter1' result='shadowBlurOuter1'></feGaussianBlur><feColorMatrix values='0 0 0 0 0   0 0 0 0 0   0 0 0 0 0  0 0 0 0.2 0' type='matrix' in='shadowBlurOuter1'></feColorMatrix></filter></defs><g id='Artboard' stroke='none' stroke-width='1' fill='none' fill-rule='evenodd'><g id='switches-dot' transform='translate(5.000000, 5.000000)' fill-rule='nonzero'><g id='Oval'><use fill='black' fill-opacity='1' filter='url(#filter-2)' xlink:href='#path-1'></use><use fill='#{$form-switch-checked-color}' xlink:href='#path-1'></use></g></g></g></svg>") !default;

$switch-bg-color: #e2e2e2 !default; // (c)
