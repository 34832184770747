//
// Check/radio
//


.form-check-input {
  width: 1.285rem;
  height: 1.285rem;
  margin-top: .0825rem; 
  vertical-align: top;
  background-color: $primary;
  background-repeat: no-repeat;
  background-position: center;
  background-size: contain;
  border: $form-check-input-border;
  appearance: none;
  color-adjust: exact; // Keep themed appearance for print
  width: 3rem;
  transition: background-position .15s ease-in-out,background-color .1s ease;

  &:active {
    filter: none;
  }

  &:focus {
    border-color: $form-check-input-focus-border;
    outline: 0;
    box-shadow: $form-check-input-focus-box-shadow;
  }

  &:checked {
    background-color: $primary;
    border-color: $primary;

    &[type="checkbox"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-input-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-input-checked-bg-image);
      }
    }

    &[type="radio"] {
      @if $enable-gradients {
        background-image: escape-svg($form-check-radio-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-check-radio-checked-bg-image);
      }
    }
  }

  &[type="checkbox"]:indeterminate {
    background-color: $primary;
    border-color: $primary;

    @if $enable-gradients {
      background-image: escape-svg($form-check-input-indeterminate-bg-image), var(--#{$variable-prefix}gradient);
    } @else {
      background-image: escape-svg($form-check-input-indeterminate-bg-image);
    }
  }

  &:disabled {
    pointer-events: none;
    filter: none;
    opacity: $form-check-input-disabled-opacity;
  }

  // Use disabled attribute in addition of :disabled pseudo-class
  // See: https://github.com/twbs/bootstrap/issues/28247
  &[disabled],
  &:disabled {
    ~ .form-check-label {
      opacity: .5;
    }
  }
}

.form-check-label {
  color: null;
  cursor: pointer;
}

//
// Switch
//

.form-switch {
  padding-left: $form-switch-padding-start;

  .form-check-input {
    width: $form-switch-width;
    margin-left: $form-switch-padding-start * -1;
    background-image: escape-svg($form-switch-bg-image);
    background-position: left center;
    border-radius: 2em;
    transition: background-position .15s ease-in-out;

    &:focus {
      background-image: escape-svg($form-switch-focus-bg-image);
    }

    &:checked {
      background-position: right center;

      @if $enable-gradients {
        background-image: escape-svg($form-switch-checked-bg-image), var(--#{$variable-prefix}gradient);
      } @else {
        background-image: escape-svg($form-switch-checked-bg-image);
      }
    }
  }
}

.form-check-inline {
  display: inline-block;
  margin-right: 1rem;
}

.btn-check {
  position: absolute;
  clip: rect(0, 0, 0, 0);
  pointer-events: none;

  &[disabled],
  &:disabled {
    + .btn {
      pointer-events: none;
      filter: none;
      opacity: .65;
    }
  }
}
