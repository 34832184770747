// Component: UI Loader
// ========================================================================

@import '../../scss/base/bootstrap-extended/include';
@import '../../scss/base/components/include';

.ui-loader {
  position: relative;
  &.show {
    // background-color: rgba($color: $black, $alpha: 0.3);
    .overlay {
      content: '';
      position: absolute;
      width: 100%;
      height: 100%;
      top: 0;
      background-color: rgba($color: $black, $alpha: 0.3);
    }

    > :first-child,
    code {
      background-color: initial;
    }
  }

  .loader {
    position: absolute;
    top: 50%;
    left: 50%;
    height: 50%;
    margin-top: -1rem;
    margin-left: -1rem;
  }
}

.ui-loader.full {
  transition: all 0.15s;
  height: 100%;
  .overlay, .loader {
    opacity: 0;
  }
  @include transition(opacity .5s ease, left 0s linear .5s);
  &.show {
    max-height: 100vh;
    height: 100%;
    overflow: hidden;
    .overlay {
      z-index: 2000;
      opacity: 1;
      background: rgba($primary, .451);
      max-height: 100vh;
    }

    .loader {
      width: 100%;
      height: 100vh;
      display: flex;
      align-items: center;
      justify-content: center;
      left: 0;
      top: 0;
      margin: 0;
      opacity: 1;
        p {
          z-index: 2001;
          font-size: 1.2rem;
        }
    }
  }
}
