// ================================================================================================
//     File Name: tables.scss
//     Description: Tables pages custom scss
//     ----------------------------------------------------------------------------------------------
//     Item Name: Vuexy  - Vuejs, HTML & Laravel Admin Dashboard Template
//     Author: PIXINVENT
//     Author URL: http://www.themeforest.net/user/pixinvent
// ================================================================================================

// table-white-space
.table-white-space {
  th,
  td {
    white-space: nowrap;
  }
}

// table th, td padding
.table {
  thead,
  tfoot {
    th {
      vertical-align: top;
      //text-transform: uppercase;
      text-transform: capitalize;
      font-size: .989rem;
      //font-size: $table-th-font-size;
      letter-spacing: 0.5px;
    }
  }
  th,
  td {
    padding: $table-cell-padding 2rem;
    vertical-align: middle;
  }
  &.table-sm {
    th,
    td {
      padding: $table-cell-padding-sm 0.5rem;
      &:first-child {
        padding-left: 0.75rem;
      }
    }
  }
}
// if we are not using table-light class then also header color should apply
.table:not(.table-dark):not(.table-light) {
  thead:not(.thead-dark) th,
  tfoot:not(.thead-dark) th {
    background-color: $table-head-bg;
  }
}
// table inside card, don't need margin bottom
.table-hover {
  tbody {
    tr {
      cursor: pointer;
    }
  }
}
// Table - hover animation
.table-hover-animation {
  background-color: $body-bg;
  thead {
    th {
      border-bottom: 0;
    }
  }
  th,
  td {
    border: 0;
  }
  tbody {
    tr {
      transition: all 0.25s ease;
      background-color: $white;
      &:hover {
        transform: translateY(-4px);
      }
    }
  }
}

// table border radius
.card .table {
  margin-bottom: 0;
  border-bottom-left-radius: $border-radius;
  border-bottom-right-radius: $border-radius;
  tbody {
    tr:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }
      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

// Contexual table inside card radius
.card {
  table {
    tr[class*='table-']:last-child {
      td:first-child {
        border-bottom-left-radius: $border-radius;
      }
      td:last-child {
        border-bottom-right-radius: $border-radius;
      }
    }
  }
}

// IE Specific CSS
@media screen and (-ms-high-contrast: active), (-ms-high-contrast: none) {
  .table-hover-animation {
    tr {
      &:hover {
        border-bottom-color: $table-border-color;
      }
    }
  }
}

.table-icon-clickeable {
  transition: .2s;
  border-radius: 50%;
  display: flex;
  width: 29px;
  height: 29px;
  align-items: center;
  padding-left: 5px;
  > i {
    font-size: 19px;
  }
}

.table-icon-clickeable:active{
  background: rgba(0, 0, 0, 0.1);
}

.image-no-information {
  height: 19em;
  opacity: .8;
  display: flex;
  justify-content: center;
    img{
      height: 100%;
    }
}

.table-image {
  display: flex;
  justify-content: center;
  overflow: hidden;
  border-radius: 50%;
  cursor: pointer;
  height: 30px;
  width: 30px;
  background-color: #c3c3c3;
  &-small {
    @extend .table-image;
    height: 20px;
    width: 20px;
  }
}

.table-icon-small {
  height: 1.2em;
  vertical-align: middle;
}

.times-timelog-list > div {
  max-height: 142px;
}

.details-cell-item-small {
  max-width: fit-content;
  min-width: 29px;
  width: 29px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: initial;

  > div {
    max-width: fit-content;
    max-height: fit-content;
    display: flex;
    > i {
      font-size: 14px;
    }
  }
}

.row-no-information-text {
  height: 42.8px;
  pointer-events: none;
  
  td {
    position: absolute;
    width: 100%;
    justify-content: center;
    display: flex;
    align-items: center;
  }
}
