$PT: rgba(126, 87, 194, 0.6);
$PR: rgba(72, 116, 151, 0.6);
$place-selected: rgba(85, 204, 63, 0.6);
$place-avaliable: rgba(63, 108, 204, 0.6);
$place-no-avaliable: rgba(204, 63, 63, 0.6);
$place-partial-avaliable: rgba(255, 106, 0, 0.6);
$place-default: rgba(114, 114, 114, 0.6);
$coworking-area: rgb(164 ,197, 149);
$meet-area: rgb(227, 215, 95);

.available-places {
  // width: calc(100% - 26px);
  // height: 50vh;
  // border: 1px solid #c4c4c4;
  // border-radius: 8px;
  margin: 13px;
  // padding: 0;
  //min-height: 382px;
  //max-height: 382px;
  max-height: 50vh;
  min-height: 250px;
  height: 100%;
  border: 1px solid $gray-200;
  border-radius: 7px;
  position: relative;
  background-color: white;
  //min-width: 805.53px;
  min-width: 100%;
  display: flex;
  flex-direction: column;
  overflow: auto;
  width: 100%;
  //height: 54vh;
  //max-height: 392px;
  align-items: center;

  .plan {
    position: relative;
    background-size: contain;
    background-repeat: no-repeat;
    background-position-y: top;
    background-position-x: left;
    width: 805.53px !important;
    height: 382px !important;
    min-height: 382px;
    max-height: 382px;
    min-width: 805.53px;
    max-width: 805.53px;
  
    @supports (object-fit: contain) {
    img {
      position: relative;
      width: 100% !important;
      height: 100% !important;
      object-fit: contain;
      object-position: center center;
    }
    }
  }

  .default-plan {
    height: 382px;
    border-radius: 7px;
    margin: 4px;
    display: flex;
    justify-content: center;
    align-items: center;
    width: 100%;
  
    img {
    width: 270px;
    }
  }
}

.plan-places-legend {
  position: relative;
  margin: 4px;
  border-radius: 7px;
  display: flex;
  flex-direction: row;
  align-items: flex-start;
  padding: 6px;
  flex-wrap: wrap;
  justify-content: center;

  .legend-item {
    width: fit-content;
    display: flex;
    margin: 0 6px;

    &-sub {
      width: 27px;
      height: 27px;
      min-width: 27px;
      min-height: 27px;
      display: flex;
      justify-content: center;
      align-items: center;
      font-size: .8rem;
      position: relative;

      &.coworking-area::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $coworking-area;
      }

      &.meet-area::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $meet-area;
      }
    
      &.place-available::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $place-avaliable;
      }

      &.place-partial-available::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $place-partial-avaliable;
      }

      &.place-no-available::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $place-no-avaliable;
      }

      &.place-selected::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        border: 2px dashed;
      }

      &.place-others::before {
        content: "";
        position: absolute;
        width: 19px;
        height: 19px;
        border-radius: 50%;
        background: $place-default;
      }
		}
  
		&-text {
      display: flex;
      align-items: center;
      padding-left: 5px;
      font-size: .8rem;
      white-space: nowrap;
      overflow: hidden;
      text-overflow: ellipsis;
      flex: 1;
		}
  }
}

.place {
  position: absolute;
  display: flex;
  justify-content: center;
  align-items: center;
  margin-top: -15px;
  margin-left: -15px;
  width: 30px;
  height: 30px;
  font-size: 0.8em;
  border-radius: 50%;
  cursor: pointer;
  color: #000;

  &.PT {
    background: $PT;
  }
  &.PR {
    background: $PR;
  }
  &.selected {
    border: 2px dashed;
  }
  &.avaliable {
    background: $place-avaliable;
  }
  &.no-avaliable {
    background: $place-no-avaliable;
  }
  &.partial-avaliable {
    background: $place-partial-avaliable;
  }
  &.default {
    background: $place-default;
  }
}

.scheduling {
  &-list > div{
    box-shadow: none !important;
  }
  &-item > .delete-icon {
    width: 20px;
    height: 20px;
    border-radius: 50%;
    background: #c3c3c3;
    position: absolute;
    top: -12px;
    right: -14px;
    align-items: center;
    justify-content: center;
    display: none;
  }
}

.scheduling-item:hover > .delete-icon {
  display: flex;
}
.scheduling-modal {
  width: 100%;
  max-width: 1048.01px;
}

@media (max-width: 1048.01px) {
  .scheduling-modal {
    max-width: 90%;
  }
}

@media (max-width: 576px) {
  .scheduling-modal {
    max-width: 100%;
  }
}