.w-fit-content {
  width: fit-content;
}

.h-fit-content {
  height: fit-content;
}

.maxw-fit-content {
  max-width: fit-content;
}

.maxh-fit-content {
  max-height: fit-content;
}

.minw-fit-content {
  min-width: fit-content;
}

.minh-fit-content {
  min-height: fit-content;
}

.image {
  img {
    height: 100%;
  }
}

.modal-image {
  width: 100%;
  background: none !important;
  border-radius: 9px;

  .modal-header {
    position: relative;
    height: 0;
    padding: 0;
    border: none;
    display: block;
    
    button {
      margin: -0.8rem -0.4rem -0.8rem auto;
    }
  }

  .image {
    width: 100%;

    img {
      width: 100%;
      min-width: 70vw;
      border-radius: 6px;
    }
  }
}

.modal-image.vertical{
  max-height: 80vh;
  max-width: 60vh;

  & .image img {
    min-width: 0vw;
  }

}

@keyframes fadeIn {
  0% {opacity:0;}
  100% {opacity:1;}
}

.fadein {
  animation: fadeIn linear .5s;
  -webkit-animation: fadeIn linear .5s;
  -moz-animation: fadeIn linear .5s;
  -o-animation: fadeIn linear .5s;
  -ms-animation: fadeIn linear .5s;
}

.i-search {
  width: 22px;
  height: 22px;
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  transform: scale(.8);

  &::before {
    content: "";
    position: absolute;
    margin: auto;
    top: 16px;
    right: 0;
    bottom: 0;
    left: 13px;
    width: 10px;
    height: 2px;
    background: white;
    transform: rotate(45deg);
    transition: all .5s;
  }
  &::after {
    content: "";
    position: absolute;
    margin: auto;
    top: -5px;
    right: 0;
    bottom: 0;
    left: -5px;
    width: 20px;
    height: 20px;
    border-radius: 50%;
    border: 2px solid white;
    transition: all .5s;
  }
}

.i-search.open{
  &::before {
    top: 0;
    left: 0;
    width: 20px;
  }
  &::after {
    top: 0;
    left: 0;
    width: 20px;
    height: 2px;
    border: none;
    background: white;
    border-radius: 0%;
    transform: rotate(-45deg);
  }
}

.btn-purple {
  background-color: #8e91c4 !important;
  border-color: #8e91c4 !important;
}

.logo {
  margin-top: 6px !important;
  margin-left: -6px !important;
  & > svg {
    height: 52px;
  }
}

.btn-clock-in-out {
  width: 112px;
  display: flex;
  align-items: center;
  justify-content: center;

  .type-timelog-icon {
    display: none;
    height: 1.0723em;
  }

  @media (max-width: 575px) {
    width: 38.88px;
    .clock-icon {
      display: none;
    }
    .type-timelog-icon {
      display: block;
    }
    > span {
      display: none;
    }
  }
}

#password-recovery {
  color: rgba($primary, .75);
  transition: $btn-transition;
  // text-decoration: none;
}

#password-recovery:hover {
  color: $primary;
  // text-decoration: none !important;
}

.work-timer {
  font-family: "Recursive", monospace;
  > span:first-child {
    margin-right: 0.25em;
  }
}


@keyframes wave {
  0% {box-shadow: 0 0 0px 0px rgba($orange, 0.5);}
  100% {box-shadow: 0 0 0px 10px rgba($orange, 0);}
}

.alert-icon {
  background-color: $orange;
  font-size: 11px;
  width: 10px;
  height: 10px;
  min-width: 10px;
  min-height: 10px;
  border-radius: 50%;
  animation: wave 1s linear infinite, blinker 1s step-start infinite;
}

.intelli-info {
  display: flex;
}

.modal-user-config {
  width: 100%;
  max-width: 688px;
}

@media (max-width: 688px) {
  .modal-user-config {
    max-width: 90vw;
  }
}

@media (max-width: 576px) {
  .modal-user-config {
    margin: 0;
    max-width: 100vw;
  }
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.blink {
  animation: blinker 1s step-start infinite;
}

.truncateText {
    flex: 1;
    white-space: nowrap;
    overflow: hidden;
    text-overflow: ellipsis;
}