.container-timeline {
  overflow-x: auto;
  overflow-y: hidden;
  width: 100%;

  .timeline {
    flex: 0 1 53px;
    height: 53px;
    min-height: 53px;
    display: grid;
    grid-template-rows: 1fr;
    margin: 2.25rem 22px 1.5rem 22px;
    transition: all 0.3s ease 0s;
    background: none;
    border-radius: 0.428rem;
    
    .bar {
      padding: 0.2rem 0rem 0.2rem 0;
      grid-column: 2/3;
      position: relative;
      display: flex;
      align-items: center;
      width: 100%;

      .texture-approval {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
          repeat;
        &-permission {
          background: url("https://staticmyintelli.s3.amazonaws.com/texture-2.png")
              repeat,
            linear-gradient(
              180deg,
              rgba(255, 245, 157, 1) 0%,
              rgba(203, 194, 109, 1) 100%
            );
        }
        &-HOUR {
          background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
              repeat,
            linear-gradient(
              180deg,
              rgba(165, 214, 167, 1) 0%,
              rgba(117, 164, 120, 1) 100%
            );
        }
        &-EXTRA {
          background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
              repeat,
            linear-gradient(
              180deg,
              rgba(255, 204, 128, 1) 0%,
              rgba(202, 155, 82, 1) 100%
            );
        }
  
        &-COMPENSATORY {
          background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
              repeat,
            linear-gradient(
              180deg,
              rgba(206, 147, 216, 1) 0%,
              rgba(156, 100, 166, 1) 100%
            );
        }
        &-PERMISSION {
          background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
              repeat,
            linear-gradient(
              180deg,
              rgba(255, 245, 157, 1) 0%,
              rgba(203, 194, 109, 1) 100%
            );
        }
        &-ABSENCE,
        &-A,
        &-DELAY {
          background: url("https://staticmyintelli.s3.amazonaws.com/texture-1.png")
              repeat,
            linear-gradient(
              180deg,
              rgba(245, 245, 245, 1) 0%,
              rgba(194, 194, 194, 1) 100%
            );
        }
      }
      
      .texture-other-day {
        background: url("https://staticmyintelli.s3.amazonaws.com/texture-other_day.png")
          repeat;
      }

      .flag_marking {
        height: 100%;
        width: fit-content;
        position: absolute;
        top: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;
        i {
          margin-bottom: 0.2rem;
          position: absolute;
          top: -22px;
        }
        svg {
          margin-bottom: 0.2rem;
          position: absolute;
          top: -16px;
          z-index: 11;
        }
        
        div.tiny-bar {
          flex: 0 1 100%;
          height: 100%;
          width: 2px;
          background-color: #37474f;
          z-index: 10;
        }
      }

      .bar_marking {
        height: 21%;
        position: absolute;
        background-color: #bfbfbf;
        z-index: 5;
      }

      .grid-step {
        height: 100%;
        width: fit-content;
        position: absolute;
        top: 0%;
        display: flex;
        flex-direction: column;
        align-items: center;
        justify-content: space-around;

        span {
          margin-bottom: 0.2rem;
          position: absolute;
          top: 102%;
          font-size: 0.7rem;
          color: rgba(0, 0, 0, 0.4);
          white-space: nowrap;
        }
        .dashed-bar,
        .solid-bar {
          flex: 0 1 100%;
          height: 100%;
          width: 1px;
          z-index: 10;
          border-left: 1px dashed rgba(0, 0, 0, 0.4);
        }

        .solid-bar {
          border-left: 1px solid rgba(0, 0, 0, 0.4);
        }
        .new-day {
          color: black;
        }
      }

      .bars-regions-container {
        display: flex;
        flex-direction: row;
        width: 100%;
        height: 100%;
        flex-wrap: wrap;
        align-items: center;

        .superior-bar-container {
          flex: 0 1 100%;
          position: relative;
          height: 14%;
        }

        .rnd-container {
          flex: 0 1 100%;
          margin: 1.5px 0;
          position: relative;
          height: 48%;
          width: 100%;
          display: flex;
          align-items: center;

          .barRnd {
            overflow: hidden;
            border-radius: 0.3rem;
          }
        }

        .inferior-bar-container {
          flex: 0 1 100%;
          position: relative;
          height: 14%;
          .bar_marking {
            height: 100%;
          }
        }
      }


    }
  }
}